import { useEffect, useState, createContext, useContext } from "react";
import { getClientsRequest, getClientRequest, createClientRequest, updateUsernameClientRequest, deleteClientRequest, updateEmailClientRequest, updatePasswordClientRequest, updateSheetClientRequest, updatePluginClientRequest, updateActiveClientRequest, updateExpirationClientRequest, updatePaydayClientRequest } from '../api/client.js'

const ClientContext = createContext()

export const useClients = () => {
    const context = useContext(ClientContext)
    if(!context) throw new Error("useClients must be used within an TaskProvider")

    return context
}

export const ClientProvider = ({children}) => {
    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState(true)
    const [createErrors, setCreateErrors] = useState([])
    const [deleteErrors, setDeleteErrors] = useState([])
    const [updateErrors, setUpdateErrors] = useState([])
    const [loadingCreateClient, setLoadingCreateClient] = useState(false)
    const [loadingDeleteClient, setLoadingDeleteClient] = useState(false)
    const [loadingUpdateClient, setLoadingUpdateClient] = useState(false)

    useEffect(() => {
        if(createErrors.length > 0 || createErrors.message) {
            const timer = setTimeout(() => {
                setCreateErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [createErrors])

    useEffect(() => {
        if(deleteErrors.length > 0 || deleteErrors.message) {
            const timer = setTimeout(() => {
                setDeleteErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [deleteErrors])

    useEffect(() => {
        if(updateErrors.length > 0 || updateErrors.message) {
            const timer = setTimeout(() => {
                setUpdateErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [updateErrors])

    const createClient = async (client) => {
        setLoadingCreateClient(true)
        try {
            const res = await createClientRequest(client)
            const newClients = [...clients, res.data]
            ordenarLista(newClients)
            setLoadingCreateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setCreateErrors([{message: error.message}])
            } else {
                setCreateErrors(error.response.data)
            }
            setLoadingCreateClient(false)
            return false
        }
    }

    const getClients = async () => {
        try {
            const res = await getClientsRequest()
            ordenarLista(res.data)
            setLoading(false)
        } catch(error) {
            console.error(error)
        }
    }

    const updateUsernameClient = async (username, newUsername) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updateUsernameClientRequest(username, newUsername)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    const deleteClient = async (username) => {
        setLoadingDeleteClient(true)
        try {
            const res = await deleteClientRequest(username)
            const clientsUpdated = clients.filter(client => client.username != username)
            ordenarLista(clientsUpdated)
            setLoadingDeleteClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setDeleteErrors([{message: error.message}])
            } else {
                setDeleteErrors(error.response.data)
            }
            setLoadingDeleteClient(false)
            return false
        }
    }

    const ordenarLista = (clients) => {
        const sortedList = [...clients].sort((a, b) => (a.username > b.username ? 1 : a.username < b.username ? -1 : 0))
        setClients(sortedList)
    }

    const updateEmailClient = async (username, email) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updateEmailClientRequest(username, email)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    const updatePasswordClient = async (username, password) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updatePasswordClientRequest(username, password)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    const updateSheetClient = async (username, sheet) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updateSheetClientRequest(username, sheet)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    const updatePluginClient = async (username, plugin) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updatePluginClientRequest(username, plugin)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    const updateActiveClient = async (username) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updateActiveClientRequest(username)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            console.log(error)
            return false
        }
    }

    const updateExpirationClient = async (username, expiration) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updateExpirationClientRequest(username, expiration)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    const updatePaydayClient = async (username, payday) => {
        setLoadingUpdateClient(true)
        try {
            const res = await updatePaydayClientRequest(username, payday)
            const clientsUpdated = clients.map(client => {
                if(client.username === username) return client = res.data
                return client
            })
            ordenarLista(clientsUpdated)
            setLoadingUpdateClient(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateErrors([{message: error.message}])
            } else {
                setUpdateErrors(error.response.data)
            }
            setLoadingUpdateClient(false)
            return false
        }
    }

    return(
        <ClientContext.Provider value={{clients, createClient, getClients, updateUsernameClient, deleteClient, loading, createErrors, setCreateErrors, loadingCreateClient, deleteErrors, setDeleteErrors, loadingDeleteClient, updateErrors, setUpdateErrors, loadingUpdateClient, updateEmailClient, updatePasswordClient, updateSheetClient, updatePluginClient, updateActiveClient, updateExpirationClient, updatePaydayClient}}>
            {children}
        </ClientContext.Provider>
    )
}